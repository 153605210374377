<template>
  <v-row v-bind="$attrs" dense align="center" justify="start">
    <v-btn
      v-if="!noProducts"
      class="text-none mr-1 mb-1"
      color="primary"
      depressed
      @click="$router.push({ name: 'offers-dashboard' })"
    >
      My Products
    </v-btn>
    <v-btn
      v-if="!noLikes"
      class="text-none mr-1 mb-1"
      color="primary"
      depressed
      @click="$router.push({ name: 'offers-likes' })"
    >
      My Likes
    </v-btn>
    <v-btn
      v-if="!noOrders"
      class="text-none mr-1 mb-1"
      color="primary"
      depressed
      @click="$router.push({ name: 'offers-my-orders' })"
    >
      My Orders
    </v-btn>
    <v-btn
      v-if="!noIncomingOrders"
      class="text-none mr-1 mb-1"
      color="primary"
      depressed
      @click="$router.push({ name: 'offers-incoming-orders' })"
    >
      Incoming Orders
    </v-btn>
  </v-row>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'BoardLinks',
  props: {
    noProducts: { type: Boolean, default: false },
    noLikes: { type: Boolean, default: false },
    noOrders: { type: Boolean, default: false },
    noIncomingOrders: { type: Boolean, default: false }
  }
}
</script>

<style></style>
