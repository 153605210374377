<template>
  <div class="likes">
    <v-card min-height="500" flat>
      <v-card-title> <BoardLinks no-likes :no-incoming-orders="!can_view_incoming_orders"></BoardLinks> </v-card-title>
      <v-card-text>
        <v-row dense align="stretch">
          <v-col cols="12" md="3" sm="12" xs="12">
            <v-card
              tile
              flat
              class="fill-height border-2 border-color--grey pa-1"
            >
              <v-list dense flat shaped>
                <v-subheader class="fw-700 primary--text fs-20">
                  My Likes
                </v-subheader>
                <v-list-item-group>
                  <v-list-item
                    v-for="item in liked_categories"
                    :class="{
                      'active-likes-list primary': filter === item.id
                    }"
                    :key="item.id"
                    @click="filterByCategory(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.title"
                        :class="[
                          filter === item.id ? 'light--text' : 'primary--text'
                        ]"
                        class="fs-17 fw-900"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text
                      v-if="item.id !== 0"
                      class="d-flex"
                    >
                      <v-chip
                        small
                        :color="filter === item.id ? 'white' : 'primary'"
                        outlined
                        :class="[
                          filter === item.id ? 'light--text' : 'primary--text'
                        ]"
                        class="border-2"
                      >
                        <span class="caption mx-auto">
                          {{ item.products_count }}
                        </span>
                      </v-chip>
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <!-- <v-divider vertical inset /> -->
          <v-col cols="12" md="9" sm="12" xs="12">
            <v-card
              class="d-flex fullwidth align-stretch"
              flat
              min-height="500"
            >
              <v-row v-if="items.length > 0" dense align="stretch">
                <v-col
                  cols="12"
                  md="3"
                  sm="6"
                  xs="12"
                  v-for="item in filtered_items"
                  :key="item.likable.id"
                >
                  <ProductCard
                    class="ma-2"
                    :product="item.likable"
                    @viewProduct="viewProduct(item.likable)"
                    @addToLikes="removeFromLike(item)"
                    @deleteProduct="confirmDeleteProduct(item.likable)"
                    @editProduct="editProduct(item.likable)"
                    @shareProduct="shareProduct(item.likable)"
                  />
                </v-col>
                <v-col cols="12" v-if="next_page_url">
                  <v-btn
                    @click="getMoreLikes"
                    block
                    depressed
                    class="text-none my-2"
                    :loading="loading"
                  >
                    View More
                  </v-btn>
                </v-col>
              </v-row>
              <Empty
                v-else
                class="mx-auto"
                headline="No likes yet!"
                icon="mdi-cards-heart"
              />
              <div v-if="loading" class="my-2 pa-1">
                <v-progress-linear :size="50" color="primary" indeterminate />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ProductViewDialog
      v-model="product_view_dialog"
      :product="activeProduct"
      @item-liked="itemLiked"
    />
    <DeleteDialog
      :open-dialog.sync="product_delete_dialog"
      title="Delete Product"
      text-content="Are you sure you want to delete this product? This will also delete all pending orders of this product "
      @delete="deleteProduct(delete_callback)"
    />
  </div>
</template>

<script>
import { offers_mixin } from '../mixin'
import request from '@/services/axios_instance'
import ProductViewDialog from '../components/ProductViewDialog.vue'
import ProductCard from '../components/ProductCard'
import DeleteDialog from '@/common/DeleteDialog'
import BoardLinks from '../components/BoardLinks.vue'

export default {
  name: 'Likes',
  components: {
    DeleteDialog,
    ProductCard,
    ProductViewDialog,
    BoardLinks
  },
  mixins: [offers_mixin],
  data: () => ({
    items: [],
    liked_categories: [],
    loading: false,
    next_page_url: null,
    filter: 0 //all
  }),
  computed: {
    filtered_items() {
      if (this.filter === 0) {
        return this.items
      }
      return this.items.filter((item) => {
        return item.likable.category.id === this.filter
      })
    }
  },
  created() {
    this.getLikesCategory()
    this.getLikes()
  },
  methods: {
    getLikesCategory() {
      request
        .get(`api/likes/categories`)
        .then(({ data }) => {
          this.liked_categories = data
        })
        .finally(() => {
          this.liked_categories.unshift({
            id: 0,
            title: 'All Categories',
            products_count: 0
          })
        })
    },
    getLikes() {
      this.loading = true
      request
        .get(`api/likes`)
        .then(({ data }) => {
          this.items = data.data
          this.next_page_url = data.next_page_url
        })
        .finally(() => (this.loading = false))
    },
    getMoreLikes() {
      this.loading = true
      request
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data)
          this.next_page_url = data.next_page_url
        })
        .finally(() => (this.loading = false))
    },
    removeFromLike(item) {
      request.post(`api/likes/${item.likable.id}`).then(({ data }) => {
        let index = this.items.findIndex(
          (i) => i.likable.id === item.likable.id
        )
        if (~index) {
          this.items.splice(index, 1)
          this.getLikesCategory()
        }
        this.appSnackbar(data.message)
      })
    },
    delete_callback(data) {
      let index = this.items.findIndex((i) => i.likable.id === data.id)
      if (~index) {
        this.items.splice(index, 1)
      }
    },
    itemLiked(item) {
      let index = this.items.findIndex((i) => i.id === item.id)
      if (~index) {
        this.items[index].is_liked = item.is_liked
        this.items[index].likes_count = item.likes_count
      }
    },
    filterByCategory(category) {
      this.filter = category.id
    }
  }
}
</script>
<style lang="scss" scoped></style>
